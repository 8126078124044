import { Alert } from 'antd';
import XOR from "../../../../src/components/reactComponents/XOR";
import RSA from "../../../../src/components/reactComponents/RSA";
import { Tags, Quote } from "../../../../src/components/reactComponents/utils/UX";
import * as React from 'react';
export default {
  Alert,
  XOR,
  RSA,
  Tags,
  Quote,
  React
};