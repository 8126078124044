module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/templates/docs.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"018bd99b-813c-5251-a110-8d9c1f629336","name":"gatsby-remark-images","version":"6.4.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1035,"sizeByPixelDensity":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.4.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/remark-math","id":"8c27c35c-e6e0-5e48-989b-330341e98094","name":"remark-math","version":"3.0.1","modulePath":"/opt/build/repo/node_modules/remark-math/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1035,"sizeByPixelDensity":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-175521274-2","head":true,"anonymize":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Learn Blockchain","short_name":"LearnBlockchain","start_url":"/?source=pwa","background_color":"#0e1b28","theme_color":"#0e1b28","display":"standalone","crossOrigin":"use-credentials","description":"Learn Blockchain from first principles","icon":"/opt/build/repo/src/1024.png","icons":[{"src":"/favicons/192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/512.png","sizes":"512x512","type":"image/png"},{"src":"/favicons/1024.png","sizes":"1024x1024","type":"image/png"}],"shortcuts":[{"name":"Cryptography 101","url":"/cryptography","description":"Basics of classical and modern cryptography"},{"name":"Cryptography in Blockchain","url":"/cryptography-in-blockchain","description":"Role of encryption and hashing in blockchain"},{"name":"How Bitcoin Works","url":"/bitcoin","description":"In-depth look at the Bitcoin protocol"},{"name":"Transactions","url":"/transactions","description":"Understanding Ethereum transactions"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"fb3158f7feaa6d57744ad04daac08e15"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/opt/build/repo/src/custom-sw-code.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
