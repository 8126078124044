import styled from '@emotion/styled';
import { Timeline, Alert } from 'antd';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { Quote, Tags } from "../../../../src/components/reactComponents/utils/UX";
import Difficulty from "../../../../src/components/reactComponents/Difficulty";
import NTransPerBlock from "../../../../src/components/reactComponents/NTransPerBlock";
import Blockchain from "../../../../src/components/reactComponents/Blockchain";
import { PeBitcoin } from "../../../../src/components/quizComponents/Bitcoin";
import * as React from 'react';
export default {
  styled,
  Timeline,
  Alert,
  OutboundLink,
  Quote,
  Tags,
  Difficulty,
  NTransPerBlock,
  Blockchain,
  PeBitcoin,
  React
};