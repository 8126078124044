import { StyledHeading } from "../../../../src/components/styles/Docs";
import { Alert, Checkbox } from 'antd';
import GasFeeOracle from "../../../../src/components/reactComponents/GasFeeOracle";
import { PeTransactions, KcTransactions } from "../../../../src/components/quizComponents/Transactions";
import * as React from 'react';
export default {
  StyledHeading,
  Alert,
  Checkbox,
  GasFeeOracle,
  PeTransactions,
  KcTransactions,
  React
};