import styled from '@emotion/styled';
import { Alert, Tabs } from 'antd';
import { Python } from '@styled-icons/fa-brands/Python';
import { Ruby } from '@styled-icons/simple-icons/Ruby';
import { Github } from '@styled-icons/simple-icons/Github';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { Quote, Tags } from "../../../../src/components/reactComponents/utils/UX";
import SHA256 from "../../../../src/components/reactComponents/SHA256";
import Merkle from "../../../../src/components/reactComponents/Merkle";
import Signing from "../../../../src/components/reactComponents/Signing";
import * as React from 'react';
export default {
  styled,
  Alert,
  Tabs,
  Python,
  Ruby,
  Github,
  OutboundLink,
  Quote,
  Tags,
  SHA256,
  Merkle,
  Signing,
  React
};