import React from 'react';
import { Alert } from 'antd';
import { Tags, Quote } from "../../../../src/components/reactComponents/utils/UX";
import Difficulty from "../../../../src/components/reactComponents/Difficulty";
import { PeBitcoin } from "../../../../src/components/quizComponents/Bitcoin";
export default {
  React,
  Alert,
  Tags,
  Quote,
  Difficulty,
  PeBitcoin
};