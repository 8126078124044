import React from 'react';
import { Button, Alert, Timeline } from 'antd';
import { Tags, Quote } from "../../../../src/components/reactComponents/utils/UX";
import { LinkOutlined } from '@ant-design/icons';
export default {
  React,
  Button,
  Alert,
  Timeline,
  Tags,
  Quote,
  LinkOutlined
};